body {
  margin: 0;
  font-family:"Roboto";
}

@font-face {
  font-family: "Gilroy light";
  src: local("Gilroy"), url(./Fonts/Gilroy/Gilroy-Light.otf) format("truetype");
}
@font-face {
  font-family: "Gilroy ExtraBold";
  src: local("Gilroy"),
    url(./Fonts/Gilroy/Gilroy-ExtraBold.otf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./Fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Light";
  src: local("Roboto"), url(./Fonts/Roboto/Roboto-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto"), url(./Fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}
